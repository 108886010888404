import React,{useState} from 'react'
import AboutSection from '../About/AboutSection'
import BannerSection from '../BannerSection'
import Clients from '../Clients'
import OurWorks from '../OurWorks'
import Ctasection from '../ctasection/Ctasection'
import Aboutmain from '../About/Aboutmain'

function About() {
  const [title, setTitle] = useState('About Us');
  return (
    <>
        <Aboutmain/>
        <AboutSection/>
        <Ctasection></Ctasection>
    </>
  )
}

export default About
