import React from 'react'
import './Ourproduct.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Mousewheel } from "swiper";
import "swiper/css";
import { useState } from "react";


const slides = [
    "https://picsum.photos/1920/1080",
    "https://picsum.photos/1920/1081",
    "https://picsum.photos/1920/1082",
    "https://picsum.photos/1920/1083",
    "https://picsum.photos/1920/1084"
  ];

const Ourproduct = () => {
    const [imagesNavSlider, setImagesNavSlider] = useState(null);
    return (

        <div className="App">

            <h2>Our Impressive <br/>Projects</h2>
            <span>Our Portfolio</span>
        <section className="slider">
          <div className="slider__flex">
            <div className="slider__col">
              <div className="slider__prev">Prev</div>
  
              <div className="slider__thumbs">
                <Swiper
                  onSwiper={setImagesNavSlider}
                  direction="vertical"
                  spaceBetween={24}
                  slidesPerView={3}
                  navigation={{
                    nextEl: ".slider__next",
                    prevEl: ".slider__prev"
                  }}
                  className="swiper-container1"
                  breakpoints={{
                    0: {
                      direction: "horizontal"
                    },
                    768: {
                      direction: "vertical"
                    }
                  }}
                  modules={[Navigation, Thumbs]}
                >
                    <SwiperSlide>
                    <div className="slider__image">
                    <h4>Ceibee</h4>
                    <p>Client Vietnam</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <h4>SaleMe.lk</h4>
                    <p>Client Sri Lanka</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <h4>RoyalKandyan</h4>
                    <p>Royal Kandyan Design & Engineering</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <h4>Ceylon Spice</h4>
                    <p>Client Australia</p>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <h4>Ocean View Beach Resort</h4>
                    <p>Sri Lankan Clients</p>
                    </div>
                    </SwiperSlide>
                </Swiper>
              </div>
  
              <div className="slider__next">Next</div>
            </div>
  
            <div className="slider__images">
              <Swiper
                thumbs={{ swiper: imagesNavSlider }}
                direction="horizontal"
                slidesPerView={1}
                spaceBetween={32}
                mousewheel= {{
                    releaseOnEdges: true,
                }}
                navigation={{
                  nextEl: ".slider__next",
                  prevEl: ".slider__prev"
                }}
                breakpoints={{
                  0: {
                    direction: "horizontal"
                  },
                  768: {
                    direction: "horizontal"
                  }
                }}
                className="swiper-container2"
                modules={[Navigation, Thumbs, Mousewheel]}
              >
                <SwiperSlide>
                <div className="slider__image">
                    <img src="./assets/img/portfolio/Client-Vietnam.png" />
                    </div>
                    <div className="slide-content-wrap">
                        <h4>Ceibee</h4>
                        <p>Vietnam base ecommerce website</p>
                    </div>
                    <div className='slider-cta-container'>
                        <a className='btn py-2 px-4 ms-3 request-quote-btn' href='https://ceibee.com/'>Visit Website</a>
                    </div>
                    {/* <div className='slider-cta-container'>
                        <div className='slider-cta1'>
                          <img src="./assets/img/icon/get-it-on.png" />
                        </div>
                        <div className='slider-cta2'>
                        <img src="./assets/img/icon/get-it-on.png" />
                        </div>
                    </div> */}
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <img src="./assets/img/portfolio/saleme.png" />
                    </div>
                    <div className="slide-content-wrap">
                        <h4>SaleMe.lk</h4>
                        <p>One of biggest classified site in srilanka that achive best marketing and advertisement website aword.  </p>
                    </div>
                    <div className='slider-cta-container'>
                        <a className='btn py-2 px-4 ms-3 request-quote-btn' href='https://www.saleme.lk/'>Visit Website</a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <img src="./assets/img/portfolio/royalkandyan-web.png" />
                    </div>
                    <div className="slide-content-wrap">
                        <h4>Royal Kandyan</h4>
                        <p>We are provides most of Digital Solution to Royal Kandyan including SharkStaff HR system, SEO and Digital Marketing service</p>
                    </div>
                    <div className='slider-cta-container'>
                        <a className='btn py-2 px-4 ms-3 request-quote-btn' href='https://royalkandyan.com/'>Visit Website</a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <img src="./assets/img/portfolio/ceylonspiceheaven.png" />
                    </div>
                    <div className="slide-content-wrap">
                        <h4>Ceylon Spice</h4>
                        <p>Ceylon Spice is ecommerce website.</p>
                    </div>
                    <div className='slider-cta-container'>
                        <a className='btn py-2 px-4 ms-3 request-quote-btn' href='https://www.ceylonspiceheaven.com.au/'>Visit Website</a>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="slider__image">
                    <img src="./assets/img/portfolio/oceanviewbeachresort.lk_.png" />
                    </div>
                    <div className="slide-content-wrap">
                        <h4>Ocean View Beach Resort</h4>
                        <p>The hotel website with wfx hotel booking system</p>
                    </div>
                    <div className='slider-cta-container'>
                        <a className='btn py-2 px-4 ms-3 request-quote-btn' href='https://www.oceanviewbeachresort.lk/'>Visit Website</a>
                    </div>
                    </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Ourproduct