import React from 'react'
import './Servicegrid.css'
import data from '../../utils/sdata.json'

const Servicegrid = () => {
    return (
        <section className="sgrid-wrapper">
        <div className="innerWidth ">
            <p className="mt-5">&nbsp;</p>
            <h2 className="mt-5">Empowering Businesses with <br/>Cutting-Edge Digital Solutions</h2>
            <span>Our Services</span>
            <div className="Scard-caontainer">
                {
                    data.map(( _card , i)=>(
                        <div key={i}>
                            <div className="s-card">
                                <div className="scard-image">
                                <img src={_card.simage} alt="Service" />
                                </div>
                                <div><h3>{_card.stitle}</h3></div>
                                <div>{_card.sdesc}</div>
                            </div>

                        </div>

                    ))
                }
            </div>
        </div>
            <img className=" w-100 img-fluid" data-wow-delay="0.9s" src="assets/img/line2.png" />

        </section>


    )
}

export default Servicegrid