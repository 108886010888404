import React from 'react'
import "./Contactmain.css"



const Contactmain = () => {
    return(
        <section className="innerpage-wrapper">
        <div className="innerWidth banner-container">
                        <h1>Reach Us Here</h1>
                        <h5>Contact Us</h5>
                    
        </div>
        <div className='wfx-contact-info'>
            <div className='wfx-contact-box'>
                <div className='wfx-x-icon'>
                <img src="./assets/img/icon/calling.png"/>
                </div>
                <div className='wfx-c-title'>
                    Call now
                </div>
                <div className='wfx-c-dec'>
                +94 76 7174 075
                </div>
            </div>
            <div className='wfx-contact-box'>
                <div className='wfx-x-icon'>
                <img src="./assets/img/icon/message.png"/>
                </div>
                <div className='wfx-c-title'>
                    Email
                </div>
                <div className='wfx-c-dec'>
                info@wfxlive.io
                </div>
            </div>
            <div className='wfx-contact-box'>
                <div className='wfx-x-icon'>
                <img src="./assets/img/icon/location.png"/>
                </div>
                <div className='wfx-c-title'>
                    Address
                </div>
                <div className='wfx-c-dec'>
                WFX Labs Pvt Ltd<br/>
                No 02, Uyanwaththa Road<br/>
Polgolla, Kandy<br/>
Sri Lanka
                </div>
            </div>
        </div>
        
        </section>
    )
}
export default Contactmain
