import React from 'react'
// import AboutSection from '../AboutSection'
import Hero from '../hero/Hero'
import Servicegrid from '../Servicesgrid/Servicegrid'
import Ourproduct from '../ourproduct/Ourproduct'
import Ctasection from '../ctasection/Ctasection'
// import Testimonials from '../Testimonials'

function Home() {
  return (
    <>
    <Hero></Hero>
    <Servicegrid></Servicegrid>
    <Ourproduct></Ourproduct>
    <Ctasection></Ctasection>
    </>
  )
}

export default Home
