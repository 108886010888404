import React from 'react'
import "./Productmain.css"

const Productmain = () => {
    return(
        <section className="innerpage-wrapper">
        <div className="innerWidth banner-container">
                        <h1>Powerful Software Products for Your Business</h1>
                        <h5>Our Products</h5>
        </div>
        </section>
    )
}
export default Productmain
