import React from 'react'
import './Ctasection.css'
import {Link} from 'react-router-dom'

const Ctasection = () => {
  
    return (
        <section className="cta-wrapper">
            <div className="innerWidth flx-container">
                <div className="hero-left flxInnerleft">
                    <div className="cta-title">
                        <h2>Let's talk about your project</h2>

                    </div>
                    <div className="cta-desc">
                        <p>Let's collaborate and bring your vision to life. Contact us today and let's discuss how we can turn your ideas into reality.</p>
                    </div>
                    <a href="tel:+94717450703" className="btn py-2 px-4 cta-btn">+94 76 7174 075</a>
                </div>
                <div className="cta-right flxInnerright">
                   
                        
                        <div className="cta-profile">
                            <div className='cta-image'>
                            <img src="./assets/img/call-center-agent.png"/>
                            </div>
                            <div className='cta-prof-details'>
                                <span className='prof-name'>Madawa Deshan</span>
                                <p>Co-founder & MD (Sri Lanka)<br/>info@wfxlive.io<br/>+94 76 7174 075</p>
                            </div>
                        </div>
                        <div className="cta-profile">
                            <div className='cta-image'>
                            <img src="./assets/img/call-center-agent.png"/>
                            </div>
                            <div className='cta-prof-details'>
                                <span className='prof-name'>Salinda Jayawardana</span>
                                <p>Co-founder & MD (UAE)<br/>info@wfxlive.io<br/>+971 58 229 5491</p>
                            </div>
                        </div>
                     
                </div>

            </div>

        </section>
    );
}

export default Ctasection