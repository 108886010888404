import React from 'react'
import "./Productmain.css"

const SharkStaff = () => {
    return (
        <section className="product-section-wrapper">
            <div className="product-container">
                <div className='product-left'>
                    <h2>Shark Staff</h2>
                    <h5>Your Comprehensive Online HR System</h5>
                    <p>Shark Staff is a cutting-edge and user-friendly online HR system designed to streamline and simplify all your human resource management needs. With its innovative features and intuitive interface, Shark Staff empowers businesses of all sizes to efficiently manage their workforce and focus on strategic HR initiatives.</p>
                </div>
                <div className='product-right'>
                    <img src="./assets/img/product/sharkstafff.jpg" />
                    <p>Now is the time to experience the efficiency, transparency, and effectiveness of Shark Staff!</p>
                    <a className='btn py-2 px-4 ms-3 request-quote-btn' href='#'>Request a Demo</a>

                    <div className='app-icons'>
                        <img src="./assets/img/icon/android.png" />
                        <img src="./assets/img/icon/apple.png" />
                    </div>

                </div>
            </div>
            <div className="product-container product-revers">
            <div className='product-right'>
                    <img src="./assets/img/product/STAYoo.jpeg" />
                    <p>Now is the time to experience the efficiency, transparency, and effectiveness of STAYoo!</p>
                    <a className='btn py-2 px-4 ms-3 request-quote-btn' href='#'>Request a Demo</a>


                </div>
                <div className='product2-left'>
                    <h2>STAYoo</h2>
                    <h5>Online Booking System</h5>
                    <p>STAYoo stands out with its advanced features, including secure payment processing, seamless integration with online travel agencies, and responsive customer support. Whether you're a traveler looking for the perfect stay or a hotelier aiming to streamline your operations, STAYoo is the ultimate solution to elevate the hotel booking experience and ensure a memorable journey for all parties involved.</p>
                </div>
            </div>
        </section>



    )
}
export default SharkStaff
