import React from 'react'

function AboutSection() {
  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
        <div className="row g-5">
                <div className="col-lg-5">

                    <p className="mb-4">At WFX LABS, we bring your digital dreams to life. As a cutting-edge IT agency, we specialize in a comprehensive suite of services tailored to empower businesses in the digital age. With an impressive global clientele, we have earned a reputation for delivering innovative solutions that drive growth and success.</p>
                    <div className="row g-0 mb-3">
                    <h5 className="fw-bold text-primary text-uppercase">Why Choose Us:</h5>
                        <div className="col-sm-8 wow zoomIn" data-wow-delay="0.2s">
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Global Presence: Our reach knows no bounds. With a diverse clientele spanning the globe, we understand the nuances of different markets and cater our solutions accordingly.</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Experienced Experts: Our team is our strength. Boasting a wealth of experience and expertise, our professionals stay ahead of the curve in the ever-evolving world of technology.</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Client-Centric Approach: Your success is our priority. We collaborate closely with you to understand your goals, ensuring our solutions are not just effective, but perfectly aligned with your vision.</h5>
                            <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Driving Innovation: Technology waits for no one, and neither do we. We stay at the forefront of technological trends, incorporating the latest tools and practices to future-proof your digital journey.</h5>
        
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="position-relative h-100">
                        <img className="position-absolute w-100 img-fluid" data-wow-delay="0.9s" src="assets/img/ai.png" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default AboutSection
