import React from 'react'
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';
import "./Hero.css"
import { Slider } from './slider';
import { Autoplay } from 'swiper';
import 'swiper/css/autoplay';


const Hero = () => {
    return(
        <section className="hero-wrapper">
            <div className="innerWidth mainflx-container">
                <div className="hero-left flxInner">
                    <div className="hero-title">
                        <h1>WFX Labs</h1>

                    </div>
                    <div className="hero-desc">
                        <p>We specialize in providing a comprehensive range of services to meet all your digital needs. From web development and custom software solutions to SEO and digital marketing, we've got you covered.</p>
                    </div>
                    <div className="hero-icon-slider">
                        <span><strong>EXPERTS IN:</strong></span>
                        <Slider/>
                    </div>
                </div>
                <div className="hero-right flxInner">
                    <div className="feature-item1">
                        <div className="feature-ovelay1">
                        <div className="feature-text">
                        <h2>Our Product  </h2>
                        <p>Our digital products are customizable to fit your specific business needs, ensuring that you get the most out of our solutions.</p>
                        </div>
                        </div>
                    </div>
                    <div className="feature-item2">
                        <div className="feature-ovelay2">
                        <div className="feature-text">
                        <h2>Our Service</h2>
                        <p>we have the expertise to drive your business forward. Maximize your potential and reach your target audience with our tailored solutions. Let us help you succeed in the digital realm. Contact us today!</p>
	
                        </div>
                        </div>
                    </div>
                </div>

            </div>
            <img className="position-absolute w-100 img-fluid" data-wow-delay="0.9s" src="assets/img/line2.png" />

        </section>
    )
}

export default Hero