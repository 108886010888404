import React,{useState,useEffect} from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function OurWorks() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [services, setServices] = useState([]);
    const backendURL = 'http://localhost:8000';
    const projectsCountShow = 6;

  useEffect(() => {
      fetch(backendURL+"/api/projects/"+projectsCountShow)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setServices(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
        
    }, []);
    if (!isLoaded) {
        return (
          <>
          <div className="container">
              <div className="row">
                  <div className="col-lg-12 col-md-12 p-0">

                  </div>
                </div>
              </div>
          
          </>
        );
      } else {
  return (
    <>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="section-title text-center position-relative pb-3 mb-4 mx-auto">
                <h5 className="fw-bold text-primary text-uppercase">Our Works</h5>
                <h1 className="mb-0">Recent Projects</h1>
            </div>
        
            <OwlCarousel items={1} className="owl-theme" loop nav={false}
            autoplayTimeout={200000} autoplay={true}> 
            {services.map((service, index) => (  

                <div key={index}>
                
                <div className="d-flex align-items-center">
                    
                    <div  className='col-md-4  d-flex flex-column align-items-center justify-content-center '>
                        <div className="ps-4 ">
                        <img src={backendURL+service.client.logo_image} className='portpolio-client-img'/>
                                <h4 className="text-primary mb-1 text-center">
                                {service.title}
                                <br/>
                                <b>{service.client.company_name}</b>
                                </h4>
                                    {service.tags != null ? (
                                        <small className="text-uppercase">{service.tags}</small>
                                    ) : (<div></div>)}
                                    
                                    {service.web_url != null ? (
                                    <a href={service.web_url} className="btn py-2 px-4 ms-3 view-all-projects-btn" target="_blank">Visit WebSite</a>
                                    ) : (<div></div>) }
                        </div>
                    </div>
                    <div  className='col-md-8'>
                        <img alt={service.client.company_name} className="img-fluid rounded" src={backendURL+service.image}/>
                    </div>
                    </div>
                            
                </div>
                  
                ))} 
            
            </OwlCarousel>  

        </div>
        
        <div className='col-md-12 d-flex flex-column align-items-center justify-content-center '>
                <a className="btn py-2 px-4 ms-3 view-all-projects-btn" href="/">View All Projects</a>
            </div>
    </div>
    </>
  )
            }
}

export default OurWorks
