import React,{useState} from 'react'
import BannerSection from '../BannerSection'
import Clients from '../Clients'
import ContactSection from '../ContactSection'
import Contactmain from  '../Contact/Contactmain'

function Contact() {
  const [title, setTitle] = useState('Contact Us');
  return (
    <>
      <Contactmain/>
       {/*<ContactSection/>*/}
       {/*<Clients/>*/}
    </>
  )
}

export default Contact
