import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';

export const Slider = () => {
    return (
      <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={4}
        autoplay={true}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide><img src='./assets/img/icon/react.svg'></img></SwiperSlide>
        <SwiperSlide><img src='./assets/img/icon/meta.png'></img></SwiperSlide>
        <SwiperSlide><img src='./assets/img/icon/laravel.png'></img></SwiperSlide>
        <SwiperSlide><img src='./assets/img/icon/js.svg'></img></SwiperSlide>
        <SwiperSlide><img src='./assets/img/icon/googlea.png'></img></SwiperSlide>
        <SwiperSlide><img src='./assets/img/icon/googleads.png'></img></SwiperSlide>
        <SwiperSlide><img src='./assets/img/icon/wordpress.png'></img></SwiperSlide>

      </Swiper>
    );
  };