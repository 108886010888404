import React,{useState} from 'react'
import Productmain from '../Products/Productmain'
import SharkStaff from '../Products/SharkStaff';
import ProjectsGrid from '../ProjectsGrid'

function Product() {
  const [title, setTitle] = useState('Our Product');
  return (
    <>
      {/* <BannerSection title={title}/> */}
      <Productmain/>
      <SharkStaff/>
    </>
  )
}

export default Product
