import React, {useState,useEffect} from 'react'
import {Link,useLocation } from 'react-router-dom'
import './Navbar.css'

function Navbar(props) {
    const location = useLocation();
    const [url, setUrl] = useState(null);
        useEffect(() => {
            setUrl(location.pathname);
        }, [location]);
  return (
    
    <>
    <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark py-3 py-lg-0 innerWidth">
          
            <Link to="/" className="navbar-brand p-0">
                    <img src="./assets/img/logo/WFX-lab-logo.png" className="top-main-logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <Link to="/" className={`nav-item nav-link ${(url === "/" ?" active" : "")}`}>Home</Link>
                        <Link to="/about" className={`nav-item nav-link ${(url === "/about" ?" active" : "")}`}>About Us</Link>
                        <Link to="/our-works" className={`nav-item nav-link ${(url === "/our-product" ?" active" : "")}`}>Our Products</Link>
                        <Link to="/contact-us" className={`nav-item nav-link ${(url === "/contact-us" ?" active" : "")}`}>Contact Us</Link>
                        
                    </div>
                    {/* <button type="button" className="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search"></i></button> */}
                    <a href="https://wfxlabs.io/docs/project-planner.pdf" target="_blank" className="btn py-2 px-4 ms-3 request-quote-btn">
                        Request A Quote
                    </a>
                    <div className="hidden-xs call-us">
                        <div className='row'>
                            <div className="col-md-3 d-flex align-items-center align-self-center">
                                <span className="fa fa-phone"></span>
                            </div>
                            <div className="col-md-9">
                                <p className="call-us-heading">Call Us Now</p>
                                <p className="call-us-heading">+94 76 7174 075</p>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
    </div>
    </>
  )
}

export default Navbar
