import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, {useState,useEffect} from 'react'
import './App.css';
import Footer from './components/Footer';
import About from './components/pages/About';
import Projects from './components/pages/Product';
import Contact from './components/pages/Contact';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


function App() { 
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [genaralInfo, setGenaralInfo] = useState([]);
  const backendURL = 'http://localhost:8000';

  useEffect(() => {
      fetch(backendURL+"/api/general-info")
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setGenaralInfo(result);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
        
    }, []);

  return (
    <>
    <Router>
      {/* <Navbarupper/> */}
      <Navbar genaralInfo = {genaralInfo} backendURL={backendURL} isLoaded={isLoaded}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/our-works" element={<Projects />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <div className="tawkto">
        <TawkMessengerReact
            propertyId="64dbbce594cf5d49dc6a9385"
            widgetId="1h7t5jp54"/>
      </div>
      <Footer genaralInfo = {genaralInfo} backendURL={backendURL}></Footer>
    </Router>
    </>
  );
}

export default App;
