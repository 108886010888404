import React from 'react'
import "./Aboutmain.css"
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';
import '../hero/Hero.css';
import { Slider } from '../hero/slider';
import { Autoplay } from 'swiper';
import 'swiper/css/autoplay';

const Aboutmain = () => {
    return(
        <section className="innerpage-wrapper">
        <div className="innerWidth banner-container">
                        <h1>Unveiling Our WFX LABS's Journey</h1>
                        <h5>About Us</h5>
                        <div className="hero-icon-slider">
                        <span><strong>EXPERTS IN:</strong></span>
                        <Slider/>
                    </div>
        </div>
        </section>
    )
}
export default Aboutmain
