import React from 'react'

function Footer(props) {
  return (
    <>
    <div>
    <div className="container-fluid dark-purpel text-light wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex flex-column align-items-center justify-content-center text-center p-4">
                    <img src="./assets/img/logo/wfx-lab-white.png" className="top-main-logo"/>
                        <p className="mt-3 mb-4">
                        {props.genaralInfo.slogan}
                        </p>
                        
                    </div>
                </div>
                <div className="col-lg-9 col-md-6">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 pt-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Sri Lanka Office</h3>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-geo-alt text-primary me-2"></i>
                                <p className="mb-0">
                                    WFX Labs Pvt Ltd,<br/>
                                    No 02, Uyanwaththa Road,<br/>
                                                    Polgolla, Kandy<br/>
                                                    Sri Lanka</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-envelope-open text-primary me-2"></i>
                                <p className="mb-0">
                                                    info@wfxlabs.io</p>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-telephone text-primary me-2"></i>
                                <p className="mb-0">+94 76 7174 075 <br/> <b>Madawa</b></p>
                            </div>
                            <div className="d-flex mt-4">

                                <a className="btn bg-pink color-white btn-square me-2" href="https://web.facebook.com/wfxlabs.io/"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn bg-pink color-white btn-square me-2" href="https://www.linkedin.com/company/wfx-labs"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn bg-pink color-white btn-square me-2" href="https://www.instagram.com/wfxlabs/"><i className="fab fa-instagram fw-normal"></i></a>
                                <a className="btn bg-pink color-white btn-square me-2" href="https://www.youtube.com/@WFXLabs"><i className="fab fa-youtube fw-normal"></i></a>
                                <a className="btn bg-pink color-white btn-square me-2" href="https://www.tiktok.com/@wfx.labs">♬</a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 pt-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">UAE Office</h3>
                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-geo-alt text-primary me-2"></i>
                              <p className="mb-0">
                                  WFX Labs Pvt Ltd,<br/>
                                  43 A, Al karama, <br/>
                              Dubai
                                  <br/>
                           United Arab Emirates</p>

                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-envelope-open text-primary me-2"></i>
                                <p className="mb-0">info@wfxlabs.io</p>

                            </div>
                            <div className="d-flex mb-2">
                                <i className="bi bi-telephone text-primary me-2"></i>
                           <p className="mb-0">+971 58 229 5491<br/> <b>Salinda</b></p>

                            </div>
                            <div className="d-flex mt-4">
                                {/* <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square" href="#"><i className="fab fa-instagram fw-normal"></i></a> */}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 className="text-light mb-0">Popular Links</h3>
                            </div>
                            <div className="link-animated d-flex flex-column justify-content-start">
                                <a className="text-light mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                <a className="text-light mb-2" href="/about"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                <a className="text-light mb-2" href="/our-works"><i className="bi bi-arrow-right text-primary me-2"></i>Our Products</a>
                                <a className="text-light" href="/contact-us"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid text-white bg-footer">
        <div className="container text-center">
            <div className="row justify-content-end">
                <div className="col-lg-12 col-md-12">
                    <div className="height-50" >
                        <p className="mb-0">&copy; WFX Labs Pvt Ltd - All Rights Reserved.
						</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="bi bi-arrow-up"></i></a>

    </div>

    </>
  )
}

export default Footer
